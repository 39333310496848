import React from 'react';

function Home() {
  return (
    <div className="home">
      <h1>Welcome to the Home Page</h1>
      <p>New and innovative IoT and Homeautomation products coming soon</p>
    </div>
  );
}

export default Home;
