import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Impressum from './components/Impressum';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        <nav className="nav">
          <div className="logo-container">
            <img src="/kwinnova-logo.png" alt="kwinnova logo" className="logo" />
          </div>
          <div className="dropdown">
            <button className="dropbtn">Menu</button>
            <div className="dropdown-content">
              <Link to="/" className="nav-link">
                Start
              </Link>
              <Link to="/home" className="nav-link">
                Home
              </Link>
              <Link to="/login" className="nav-link">
                Login
              </Link>
              <Link to="/impressum" className="nav-link">
                Impressum
              </Link>
            </div>
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
      </div>
    </Router>
  );
}

function StartPage() {
  return (
    <div className="start-page">
      <h1>Welcome to the Start Page</h1>
      <p>New and innovative IoT and Homeautomation products coming soon</p>
      <p>Contact: keith@kwinnova.com</p>
    </div>
  );
}

export default App;
